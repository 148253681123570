import React from 'react';
import AboutImage from '../images/heroine-figure.jpg';
import {Row, Col} from 'react-bootstrap';
import Layout from '../layouts';

export default (props) => (
  <Layout nav="about">
    <Row>
    <Col sm={12} md={6} mdOffset={3}>
      <div className="about-content about-read" style={{textAlign: 'center', marginBottom: '30px'}}>
        <img src={AboutImage} style={{marginBottom: '30px'}} alt="Heroine Figurine sitting in front of grapes"/>
        <p className="text-1" style={{color: '#000', fontSize: '24px', lineHeight: '32px', marginTop: '20px', fontStyle: 'italic'}}>At Iconic, we have one core mission: to make wines we want to drink.</p>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
          Our first vintage was 90 cases of Chardonnay in neutral oak barrels. We named the wine Heroine to capture its grace and elegance, while still reflecting the powerful terroir of California, and we loved the idea of putting a true American art form – comic book art – on the label of our American wine. When it all sold in 6 weeks, we realized we might have more than just a side project on our hands—this brand was becoming a real business.
        </p>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
          We named our winery Iconic because we aspire to establish a new benchmark for California wine: wine that is authentically Californian but more restrained than the “blockbuster” wines that became the norm in the 1990’s.
        </p>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
        Birk & Karl
        </p>
        <h2 style={{textAlign: 'center', marginTop: '100px', marginBottom: '30px'}}>About the Winemaker</h2>
        <img src="/images/birk-o-halloran.jpg" style={{height: '320px'}} alt="Picture of Birk O'Halloran"/>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
          Birk O’Halloran’s entire career has consisted of “drinking his way up the supply chain.”
        </p>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
          Born in California but raised in Colorado, Birk attended Cornell University’s Hotel Program, where wine became his subject of study and, soon after, his career of choice. Birk continued his studies at the International Wine Guild before breaking ground (literally) in the wine industry working to build three wine shops for various owners. While completing the final wine shop in Princeton NJ, Birk met the founders of a small import company called Acid Inc. Over the next eight years, Birk helped transform Acid Inc. into a national wine importer and distributor, eventually becoming the company’s domestic brand manager and recruiting brands that have become some of the biggest names in California wine.
        </p>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
          Birk was quickly building a network of great mentors and amazing fruit sources in California, which proved important for the founding of Iconic in 2011. After many years splitting his time between production and sales, Birk eventually made Iconic his full-time job.
        </p>
        <p className="text-2" style={{color: '#000', fontSize: '20px', marginTop: '40px', textAlign: 'left', lineHeight: '32px', fontWeight: 300}}>
          Birk’s winemaking is a patchwork of philosophies borrowed from all the winemakers he has had the pleasure of working with over the years, including both Californian and European mentors. A proud producer of “Dogma Free” wines, Birk’s commitment is always to a delicious finished product over any single, strict winemaking philosophy.
        </p>
      </div>
    </Col>
    </Row>
    </Layout>
)
